import * as Action from "@actions/index";

const InitialState = {
  tariffs: [],
  promoDate: "2025-01-25T00:00:00+03:00",
  currentTariff: null,
  currentPayment: "rub",
  paymentLink: "",
};

export default function Payment(state = InitialState, action: any) {
  switch (action.type) {
    case Action.Payment.SET_TARIFF:
      return { ...state, currentTariff: action.data };
    case Action.Payment.SET_PAYMENT:
      return { ...state, currentPayment: action.data };
    case Action.Payment.SET_PAYMENT_LINK:
      return { ...state, paymentLink: action.data };
    default:
      return state;
  }
}
